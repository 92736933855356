* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.animate-bottom {
  transform: translateY(120px);
  opacity: 0.3;
  animation: animate-b 1s cubic-bezier(0, 0, 0.8, 1);
  animation-fill-mode: forwards;
}

@keyframes animate-b {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}